@import url("https://fonts.googleapis.com/css2?family=Mukta&family=Oswald:wght@300&display=swap");

/*********************VARIABLES**********************/

:root {
    --white: #fff;
    --black: #000;
    --orange-primary: #f48f51;
    --blue-primary: #6187ac;
}
/****************************************************/

/*********************All**********************/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

body {
    margin: 0;
    font-family: "Mukta", sans-serif;
    font-size: 25px;
}

.main-container {
    text-align: center;
    min-height: calc(100vh);
    position: relative;
    overflow: hidden;
}

.main {
    min-height: 200vh;
    padding-top: 5rem;
    /*background-color: #dcf5fc;*/
}
/****************************************************/

/*********************TITLES**********************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Oswald", sans-serif;
}

h1 {
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2),
        0 5px 5px rgba(97, 135, 172, 0.2);
}

h2 {
    font-size: 2em;
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2),
        0 5px 5px rgba(97, 135, 172, 0.2);
}

h3 {
    font-size: 1.1em;
    text-shadow: 0 5px 5px rgba(97, 135, 172, 0.2),
        0 5px 5px rgba(97, 135, 172, 0.2);
}
/**************************************************/

span {
    font-family: "Oswald", sans-serif;
}

/*********************RESPONSIVE**********************/

@media screen and (min-width: 768px) and (max-width: 1024px) {
    h1,
    h2 {
        font-size: 1.5em;
    }
}

@media screen and  (max-width: 767px) {
    h1,
    h2 {
        font-size: 1.4em;
    }
}

/**************************************************/
