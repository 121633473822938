/*********************CONTENT***************************/
.caroussel-content-1 {
    position: relative;
}

.caroussel-content-2 {
    position: relative;
}
/****************************************************/

/*********************TEXT***************************/

.caroussel-text-1 {
    width: 60%;
    position: absolute;
    top: 20%;
    transform: translateY(-10%);
    left: 4%;
    text-align: left;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 2em;
}

.caroussel-text-2 {
    width: 60%;
    position: absolute;
    top: 20%;
    transform: translateY(-10%);
    left: 36%;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 2em;
}

.caroussel-text-p {
    font-size: 2em;
}
/****************************************************/

/*********************TITLE***************************/

.caroussel-text-title {
    color: var(--orange-primary);
    font-size: 3em;
    font-weight: bold;
}
/****************************************************/

/*********************LIST***************************/

.caroussel-text-list {
    font-size: 1.5em;
    list-style-type: none;
}
/****************************************************/

/*********************ICON***************************/

.caroussel-icon {
    color: var(--orange-primary);
    font-size: 1em;
    margin-right: 0.5em;
}
/****************************************************/

/*********************DOTS***************************/

.slick-dots {
    bottom: 5% !important;
}

.slick-dots li button:before {
    font-size: 15px !important;
}
/****************************************************/

/*********************BUTTON***************************/

.caroussel-button {
    cursor: pointer;
    text-decoration: none;
    color: var(--orange-primary);
    border: none;
    background: transparent;
    font-family: "Oswald", sans-serif;
    font-size: 1em;
    font-weight: 800;
}

.caroussel-button:hover {
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 1574px) {
    .caroussel-img {
        width: 100vw !important;
        height: 500px !important;
        object-fit: cover;
    }

    .caroussel-text-title {
        font-size: 1.8em;
    }

    .caroussel-text-1 {
        padding: 1.5em;
    }

    .caroussel-text-2 {
        padding: 1.5em;
    }

    .caroussel-text-list {
        font-size: 1em;
    }
    .caroussel-text-p {
        font-size: 1em;
    }
}

@media screen and (max-width: 1480px) {
    .caroussel-img {
        width: 100vw !important;
        height: 500px !important;
        object-fit: cover;
    }

    .caroussel-text-title {
        font-size: 1.8em;
    }

    .caroussel-text-1 {
        padding: 1.5em;
    }

    .caroussel-text-2 {
        padding: 1.5em;
    }

    .caroussel-text-list {
        font-size: 1em;
    }
    .caroussel-text-p {
        font-size: 1em;
    }
}

@media screen and (max-width: 1049px) {
    .caroussel-img {
        width: 100vw !important;
        height: 420px !important;
        object-fit: cover;
    }

    .caroussel-text-title {
        font-size: 1.5em;
    }

    .caroussel-text-1 {
        padding: 1.3em;
    }

    .caroussel-text-2 {
        padding: 1.5em;
    }

    .caroussel-text-list {
        font-size: 0.8em;
    }
    .caroussel-text-p {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 767px) {
    .caroussel-img {
        width: 100vw !important;
        height: 380px !important;
        object-fit: cover;
    }
    .caroussel-text-title {
        font-size: 1.5em;
    }
    .caroussel-text-1 {
        width: 80%;
        top: 50%;
        transform: translateY(-50%);
        left: 10%;
        padding: 1em;
    }
    .caroussel-text-list {
        font-size: 0.8em;
    }
    .caroussel-text-2 {
        width: 80%;
        top: 50%;
        transform: translateY(-50%);
        left: 10%;
        padding: 1em;
    }

    .caroussel-text-p {
        font-size: 0.8em;
    }

    .slick-dots li button:before {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 450px) {
    .caroussel-img {
        height: 350px !important;
    }

    .caroussel-text-1 {
        width: 100%;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    .caroussel-text-2 {
        width: 100%;
        left: 0;
        padding: 1.3em;
        background: rgba(0, 0, 0, 0.4);
    }

    .caroussel-text-title {
        font-size: 1.3em;
    }
    .caroussel-text-list {
        font-size: 0.7em;
    }

    .caroussel-text-p {
        font-size: 0.7em;
    }
}
/****************************************************/
