/*********************CONTAINER****************************/

.medical-container {
    /*background-color: #dcf5fc;*/
    background: linear-gradient(90deg, #72bfd8, #eff8fc);
    padding: 0 0 6rem 0;
}
/****************************************************/

/*********************MEDICAL LEFT COLUMN****************************/

.medical-left-column {
    width: 45%;
    background-color: var(--white);
    box-shadow: 0 14px 28px rgb(244 143 81 / 20%),
        0 10px 10px rgb(244 143 81 / 40%);
    border-radius: 6px;
    padding: 2em;
}

.medical-left-column:hover {
    background: linear-gradient(90deg, #c95a10, #ef7f34);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
    color: var(--white);
}

.medical-left-column:hover .medical-simu-title {
    color: #2274a5;
    border-bottom: solid 1px #2274a5;
}

.medical-left-column:hover .medical-image-1 img {
    filter: brightness(0.8);
}
/****************************************************/

/*********************MEDICAL RIGHT COLUMN****************************/

.medical-right-column {
    width: 45%;
    background-color: var(--white);
    box-shadow: 0 14px 28px rgb(244 143 81 / 20%),
        0 10px 10px rgb(244 143 81 / 40%);
    border-radius: 6px;
    padding: 2em;
}

.medical-right-column:hover {
    background: linear-gradient(90deg, #c95a10, #ef7f34);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
    color: var(--white);
}

.medical-right-column:hover h4 {
    color: #2274a5;
}

.medical-right-column:hover .medical-image-2 img {
    filter: brightness(0.8);
}

.medical-right-column:hover .medical-icon-2 {
    color: #2274a5;
}
/****************************************************/

/*********************CONTENT****************************/

.medical-content {
    display: flex;
}

.medical-description {
    padding: 1em;
    font-size: 1.1em;
}

.medical-text {
    object-fit: cover;
    padding: 2em;
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
}
/****************************************************/

/*********************IMAGES****************************/

.medical-images {
    width: 100%;
}

.medical-image-2 {
    padding-top: 1.5em;
}
.medical-image-1 img {
    border-radius: 6px;
}

.medical-image-2 img {
    border-radius: 6px;
}
/****************************************************/

/*********************TITLE****************************/

.medical-big-title {
    color: var(--orange-primary);
}

.medical-title {
    color: #2274a5;
    padding: 0.7em 0;
}

.medical-tools h4 {
    color: var(--orange-primary);
    padding: 0.5em;
}

.medical-simu-title {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom: solid 1px var(--orange-primary);
    color: var(--orange-primary);
}

.medical-simu-type-1 {
    padding: 0.7em 0;
}

.medical-simu-type-2 {
    padding: 0.7em 0;
}

.medical-simu-type-3 {
    padding: 0.7em 0;
}

.medical-simu-type-4 {
    padding: 0.7em 0;
}
/****************************************************/

/*********************LIST***************************/

.medical-simu-list {
    text-align: left;
    list-style-type: none;
}
/****************************************************/

/*********************ICONS***************************/

.medical-icon-1 {
    color: #2274a5;
    font-size: 0.8em;
    margin-right: 0.5em;
}

.medical-icon-2 {
    color: var(--orange-primary);
    font-size: 1em;
    margin-right: 0.5em;
}

.medical-icon-little {
    color: #2274a5;
    font-size: 0.5em;
    margin-right: 1em;
    padding-bottom: 0.3em;
}
/****************************************************/

/*********************RESPONSIVE**************************/

@media screen and (min-width: 1111px) and (max-width: 1626px) {
    .medical-simu-type-1 {
        padding-bottom: 2.2em;
    }

    .medical-simu-type-2 {
        padding-bottom: 2.2em;
    }

    .medical-simu-type-3 {
        padding-bottom: 2.2em;
    }

    .medical-simu-type-4 {
        padding-bottom: 2.2em;
    }
}

@media screen and (max-width: 1110px) {
    .medical-left-column {
        width: 90%;
        margin-bottom: 1.5em;
        padding: 0.5em;
    }

    .medical-right-column {
        width: 90%;
        padding: 0.5em;
    }

    .medical-image-2 img {
        padding-top: 0;
    }

    .medical-text {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .medical-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .medical-big-title {
        width: 90%;
    }

    .medical-description {
        width: 90%;
        font-size: 0.8em;
    }

    .medical-text {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .medical-left-column {
        width: 90%;
        margin-bottom: 2em;
        padding: 0.8em;
        font-size: 0.75em;
    }

    .medical-right-column {
        width: 90%;
        padding: 0.8em;
        font-size: 0.75em;
    }

    .medical-image-2 img {
        margin-top: 10px;
        padding-top: 0;
    }
}
/****************************************************/
