/*********************CONTAINER***************************/
.cards-container {
    margin: 3em 0;
    display: flex;
    justify-content: center;
}

.card-container {
    width: 50%;
    background: linear-gradient(90deg, #72bfd8, #eff8fc);
    display: flex;
    justify-content: space-around;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
}

.card-container:hover {
    background: linear-gradient(90deg, #c95a10, #ef7f34);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
}

.card-container:hover .card-profil img {
    filter: brightness(0.8);
}
/****************************************************/

/*********************CONTENT***************************/

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
}

.card-logo {
    padding-top: 1em;
    width: 45%;
}

.card-title {
    padding: 1.3em 1em 0.3em 1em;
}

.card-profil {
    padding-top: 0.5em;
    width: 20%;
}
.card-profil img {
    border: 2px solid white;
    border-radius: 2.5%;
}

.card-name {
    padding: 0 2em 1.5em 2em;
    color: var(--orange-primary);
}

.card-description {
    padding: 0 3em 3em 3em;
    font-size: 0.8em;
}

.card-social {
    padding: 1em;
}

.linkedin {
    font-size: 45px;
    color: #f48f51;
}
/****************************************************/

/*********************CONTENT HOVER***************************/

.card-container:hover .card-content {
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
}

.card-container:hover .card-title {
    color: var(--white);
}

.card-container:hover .card-name {
    color: var(--black);
}

.card-container:hover .card-description {
    color: var(--white);
}

.card-container:hover .linkedin {
    color: var(--white);
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 1574px) {

    .card-container {
        width: 60%;
    }
}

@media screen and (max-width: 1110px) {
    .card-profil {
        width: 25%;
    }
}

@media screen and (max-width: 767px) {
    .cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1em 0 1.5em 0;
    }

    .card-container {
        width: 90%;
        margin-top: 1.5em;
    }

    .card-content {
        padding: 0.5em;
    }

    .card-container:hover .card-content {
        transform: scale(1.03);
    }

    .card-logo {
        width: 50%;
    }

    .card-title {
        padding: 0.5em 0.5em 0.3em 0.5em;
    }

    .card-profil {
        width: 35%;
    }

    .card-name {
        padding: 0 0.5em 0.5em 0.5em;
    }

    .card-description {
        padding: 0 1.5em 1.5em 1.5em;
    }
}
/****************************************************/
