/*********************CONTAINER***************************/

.about-container {
    padding: 8rem 0 0 0;
    background-color: var(--white);
}
/****************************************************/

/*********************TITLE***************************/

.about-big-title {
    color: var(--orange-primary);
    padding-bottom: 0.7em;
}

.about-title {
    color: var(--blue-primary);
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {
    .about-big-title {
        font-size: 1.4em;
    }

    .about-title {
        font-size: 1em;
    }
}
/****************************************************/
