.plus-container {
        padding: 8rem 0 6rem 0;
        background-color: var(--white);
}

.plus-big-title {
    color: var(--orange-primary);
    padding-bottom: 0.7em;
}

.plus-content {
    padding: 3em 2em 0em;
    display: flex;
    justify-content: space-around;
}

.plus-participation-container {
    width: 45%;
    padding: 2em;
    background: linear-gradient(90deg, #72bfd8, #eff8fc);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
}

.plus-participation-container:hover {
    background: linear-gradient(90deg, #c95a10, #ef7f34);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
    color: var(--white);
}

.plus-participation-container:hover .plus-participation-icon-1 {
    color: var(--white);
}
.plus-participation-container:hover .plus-link {
    color: var(--black);
}
.plus-participation-container:hover .plus-p {
    color: var(--white);
}
.plus-participation-container:hover img{
    filter: brightness(0.8);
}



.plus-title {
    margin-bottom: 1em;
}

.plus-participation-icon-1 {
    color: var(--orange-primary);
    font-size: 1em;
    margin-right: 0.5em;
}

.plus-participation-title {
    color: var(--blue-primary);
    padding-bottom: 0.7em;
}

.plus-participation-container-list-picture {
width: 100%;
display: flex;
justify-content:center;
margin-top: 1em;
}

.plus-list-1 {
    color: var(--orange-primary);
    width: 50%;
    text-align: left;
    list-style-type: none;
    padding-bottom: 0.7em;
}

.plus-icon-little {
    color: #2274a5;
    font-size: 0.5em;
    margin-right: 1em;
    padding-bottom: 0.3em;
}

.plus-icon-little-2 {
    color: var(--orange-primary);
    margin-right: 0.5em;
}

.plus-participation-picture {
    width: 50%;
}

.plus-participation-picture img{
    border: 2px solid white;
    border-radius: 5px;
}



.plus-animation-container {
    width: 45%;
    padding: 2em;
    background: linear-gradient(90deg, #72bfd8, #eff8fc);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
}

.plus-animation-container:hover {
    background: linear-gradient(90deg, #c95a10, #ef7f34);
    filter: brightness(1.25);
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
    color: var(--white);
}

.plus-animation-container:hover .plus-icon-little-2 {
        color: var(--blue-primary);
    }

    .plus-animation-container:hover .plus-link {
        color: var(--blue-primary);
    }

.plus-animation-container:hover .plus-participation-icon-1 {
    color: var(--white);
}

.plus-animation-container:hover .plus-p {
    color: var(--white);
}

.plus-animation-container:hover img{
    filter: brightness(0.8);
}

.plus-activity-container {
text-align: left;
margin-bottom: 1em;
}

.plus-p {
    color: var(--black);
    font-size: 0.8em;
}

.plus-animation-picture {
    width: 80%;
}

.plus-animation-picture img {
    border: 2px solid white;
    border-radius: 5px;
}

.plus-link {
    color: var(--orange-primary);
    cursor: pointer;
    text-decoration: none;
}


/*********************RESPONSIVE**************************/

@media screen and (min-width: 1111px) and (max-width: 1626px) {
    .plus-content {
        padding: 3em 0 0 0;
        flex-direction: column;
        align-items: center;
    }

    .plus-participation-container {
        width: 80%;
        padding: 1em;
        margin-bottom: 2em;
    }

    .plus-animation-container {
        width: 80%;
        padding: 1em;
    }
}

@media screen and (max-width: 1110px) {
    .plus-content {
        padding: 3em 0 0 0;
        flex-direction: column;
        align-items: center;
    }

    .plus-participation-container {
        width: 90%;
        padding: 1em;
        margin-bottom: 2em;
    }

    .plus-participation-container-list-picture {
        flex-direction: column;
        align-items: center;
    }

    .plus-list-1 {
        width: 50%;
    }

    .plus-participation-picture {
        width: 70%;
    }

    .plus-animation-container {
        width: 90%;
        padding: 1em;
    }

    .plus-animation-picture {
        width: 80%;
    }


}

@media screen and (max-width: 767px) {
    .plus-content {
        padding: 3em 0 0 0;
        flex-direction: column;
        align-items: center;

    }

    .plus-participation-container {
        width: 90%;
        padding: 1em;
        margin-bottom: 2em;
    }
    .plus-participation-container-list-picture {
        flex-direction: column;
        align-items: center;
    }

    .plus-list-1 {
        width: 100%;
    }

    .plus-participation-picture {
        width: 100%;
    }

    .plus-animation-container {
        width: 90%;
        padding: 1em;
    }

    .plus-animation-picture {
        width: 100%;
    }
}

@media screen and (min-width: 470px) and (max-width: 767px) {

    .plus-list-1 {
        width: 70%;
    }

    .plus-participation-picture {
        width: 70%;
    }

}
/****************************************************/