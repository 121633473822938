/*********************CONTAINER***************************/

.contact-container {
    background: linear-gradient(90deg, #72bfd8, #eff8fc);
    padding: 8rem 0;
    z-index: 1;
}
/****************************************************/

/*********************TITLE***************************/

.contact-big-title {
    color: var(--orange-primary);
}
/****************************************************/

/*********************ALL CONTENT***************************/

.contact-all-content {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/****************************************************/

/*********************LEFT CONTENT***************************/

.contact-left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.contact-left-text {
    font-size: 0.8em;
    margin-bottom: 20px;
}

.contact-name-medisimus {
    font-weight: bold;
    margin-right: 0.2em;
    margin-bottom: 0.23em;
    color: var(--orange-primary);
}

.contact-left-infos {
    font-size: 0.9em;
    width: 80%;
    display: flex;
    height: 55px;
    align-items: center;
    padding-left: 15px;
    border-radius: 6px;
    background: var(--white);
    margin: 0.5rem 0 0.5rem 0;
    color: #000;
    box-shadow: 0 14px 28px rgba(97, 135, 172, 0.05),
        0 10px 10px rgba(97, 135, 172, 0.6);
}

.contact-left-icons {
    color: var(--orange-primary);
    font-size: 20px;
    margin-right: 8px;
}

.contact-left-icon-linkedin {
    cursor: pointer;
    color: var(--orange-primary);
    font-size: 23px;
    margin-right: 8px;
}

.contact-left-infos-name {
    color: black;
    cursor: pointer;
    text-decoration: none;
    margin-right: 5px;
}

.contact-name-medisimus-2 {
    font-weight: bold;
    margin-left: 0.2em;
    margin-bottom: 0.23em;
    color: var(--orange-primary);
}
/****************************************************/

/*********************RIGHT CONTENT***************************/

.contact-right-content {
    width: 100%;
    margin-top: 30px;
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {
    .contact-all-content {
        padding: 0;
        width: 90%;
    }

    .contact-big-title {
        font-size: 1.4em;
    }

    .contact-left-text {
        font-size: 0.75em;
    }

    .contact-left-infos {
        font-size: 0.6em;
        width: 100%;
    }

    .contact-left-info-linkedin {
        font-size: 0.6em;
        width: 100%;
    }

    .contact-left-icons {
        font-size: 18px;
        margin-right: 10px;
    }
}
/****************************************************/
