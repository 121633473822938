/*********************CONTAINER***************************/
.footer-container {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 80px;
    background-color: #dcf5fc;
    background-image: url("../../assets/banniere-header.jpg");
    background-position: bottom;
    background-size: cover;
}
/****************************************************/

/*********************CONTENT***************************/

.footer-content {
    color: var(--white);
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer-brother {
    display: flex;
    height: 48px;
}

/****************************************************/

/*********************LOGO***************************/

.footer-logo {
    width: 200px;
}

.footer-logo-brother {
    width: 125px;
}
/****************************************************/

/*********************LINK***************************/

.open-cmp-link {
    cursor: pointer;
}

.email-link {
    color: var(--white);
}
/****************************************************/

/*********************RESPONSIVE***************************/

@media screen and (max-width: 767px) {

    .footer-logo {
        width: 150px;
    }

    .footer-logo-brother {
        width: 115px;
    }
}

@media screen and (max-width: 450px) {
    .footer-logo {
        width: 130px;
    }

    .footer-logo-brother {
        width: 110px;
    }
}
/****************************************************/
