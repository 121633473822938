/*********************CONTAINER****************************/

.vr-container {
    background-color: var(--white);
    padding: 6rem 0 0 0;
}
/****************************************************/

/*********************CONTENT****************************/

.vr-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vr-text {
    text-align: center;
    opacity: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vr-description {
    padding: 1em;
}
/****************************************************/

/*********************IMAGES***************************/

.vr-images {
    width: 100%;
}

.vr-image-1 {
    width: 100%;
}
/****************************************************/

/*********************TITLES****************************/
.vr-big-title {
    color: var(--orange-primary);
}

.vr-title {
    color: var(--blue-primary);
    padding-bottom: 0.7em;
}
/****************************************************/

/*********************LIST****************************/

.vr-list-1 {
    color: var(--orange-primary);
    text-align: left;
    list-style-type: none;
    padding-bottom: 0.7em;
}

.vr-list-2 {
    /*text-align: left;*/
    list-style-type: none;
}

.vr-list-3 {
    text-align: left;
    list-style-type: none;
}

.vr-tools {
    padding-top: 0.5em;
}
/****************************************************/

/*********************ICONS****************************/

.vr-icon-1 {
    color: #2274a5;
    font-size: 0.8em;
    margin-right: 0.5em;
}

.vr-icon-2 {
    color: var(--orange-primary);
    font-size: 1em;
    margin-right: 0.5em;
}

.vr-icon-little {
    color: #2274a5;
    font-size: 0.5em;
    margin-right: 1em;
    padding-bottom: 0.3em;
}
/****************************************************/

/*********************RESPONSIVE****************************/
@media screen and (min-width: 860px) and (max-width: 1000px) {
    .vr-watchers {
        width: 90%;
    }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
    .vr-type {
        width: 90%;
    }
}

@media screen and (max-width: 767px) {


    .vr-text {
        font-size: 0.75em;
    }

    .vr-description {
        width: 90%;
    }

    .vr-type {
        width: 90%;
    }

    .vr-tools {
        width: 90%;
    }
}
/****************************************************/
